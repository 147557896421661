import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import adService from './service/adService';
import { MAX_AD_TIME } from '../services/ABeCProperties'
import VizSensor from 'react-visibility-sensor';
import styles from './styles/AdStyle.module.css'
import { TitleHeading } from '../UIElements/headings/TitleHeading';
import largeRectangle from '../../assets/largeRectangle.png'

class RightSideBar extends Component {

    /**
     * This component renders two Large Rectangle Ads
     * the same component is used in the left side of the page on Login and Registration related pages
     * 
     * in order to prevent component from getting reload every time there is change in url this component is mounted on root path (/)
     * in APP.js component
     * 
     * there are two timer running saparately for each ad 
     * every time there is change in page it looks up for the ads for that page
     *   if there are no ad currently being shown and there are ads in the fetch response it start showing ad
     *     first LR will show from start to end
     *     second LR will go from end to start
     *   
     *   if there is any ad already being shown and it has completed it max time defined in MAX_AD_TIME in ABeCProperties
     *   then it start showing newly fetched ads if there are any else it will reset to filler ad 
     *   based on current postion of component on page in case if it in right side
     *     www.files.aajubaaju.in/assets/LargeRectangle-RightColumn.jpg
     *   else if it in left side
     *     www.files.aajubaaju.in/assets/LargeRectangle-LoginRegistration.jpg
     * 
     *   if there is any ad already being shown and it has time remaing to complete 
     *     if there is any ad in fetch result will be kept in next ad variable and will start showing once current ad reaches time limit
     *     if there are no ads in fetch result once current ad reaches time limit then it will be reset to filler ad
     * 
     * once any ad reaches MAX_AD_TIME it calls an api to update view count and do futher processing
     * 
     * Timer : 
     *   any timer will start only if component is at leas half visible
     *                             web page tab is active and in focus on browser window
     *                             popup ad is not open
     * 
     * Component requireds props 
     *   current_coords (current geo cordinates of the user)
     *   search_data
     *       when on listing page setting such ad categories and location for the listing will be used as search data
     *       on lookup page any search query added by user will be used as search data
     *   content_page 
     *      an string returned from abecPages based on current url
     *   isPopupOpen (to determine wether or not popup ad is open)
     *   isPageVisible (to determine wether or not web page is visible)
     * 
     */

    constructor(props) {
        super(props);
        this.ad1Seconds = 0;
        this.ad2Seconds = 0;

        this.currentAd1 = {};
        this.nextAd1 = {};
        this.currentAd2 = {};
        this.nextAd2 = {};

        this.headerHeight = 455;
        this.headerSliderHeight = 455;

        this.state = {
            ad1: {
                adLink: largeRectangle
            },
            ad2: {
                adLink: largeRectangle
            },
            isAd1Visible: false,
            isAd2Visible: false,
            isPageVisible: true,
            freezClass: '',
            viewed1: {},
            viewed2: {},
            fillerAd: '',
        }
    }

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    freez = () => {
        if (this.props.content_page === 'PaymentPage') {
            /**
             * Paymant page does not have banner therefor right side bar will not scroll
             */
            this.setState({ freezClass: styles.freez })
        } else if (this.props.content_page === 'ListingPage') {
            if (window.scrollY > this.headerSliderHeight && this.state.freezClass !== 'freez') {
                this.setState({ freezClass: styles.freez })
            }
            else if (window.scrollY < this.headerSliderHeight && this.state.freezClass !== '') {
                this.setState({ freezClass: '' })
            }
        }
        else {
            if (window.scrollY > this.headerHeight && this.state.freezClass !== 'freez') {
                this.setState({ freezClass: styles.freez })
            }
            else if (window.scrollY < this.headerHeight && this.state.freezClass !== '') {
                this.setState({ freezClass: '' })
            }
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.freez);
    }

    componentWillUnmount() {
        this.stopFirstTimer();
        this.stopSecondTimer();
        window.removeEventListener('scroll', this.freez);
    }

    componentDidUpdate(prevProps, prevState) {
        const banner_ad = document.getElementById('banner-ad');
        if (banner_ad != null) {
            this.headerHeight = banner_ad.clientHeight + 55;
            this.headerSliderHeight = banner_ad.clientHeight + 150
        }
        const visiblityPropChanged = this.props.isPageVisible !== prevProps.isPageVisible || this.props.isPopupOpen !== prevProps.isPopupOpen
        if (this.state.ad1.id) {
            if (visiblityPropChanged || this.state.isAd1Visible !== prevState.isAd1Visible) {
                if (this.state.isAd1Visible && this.props.isPageVisible && !this.props.isPopupOpen)
                    this.startFirstTimer()
                else
                    this.stopFirstTimer()
            }
        }

        if (this.state.ad2.id) {
            if (visiblityPropChanged || this.state.isAd2Visible !== prevState.isAd2Visible) {
                if (this.state.isAd2Visible && this.props.isPageVisible && !this.props.isPopupOpen)
                    this.startSecondTimer()
                else
                    this.stopSecondTimer()
            }
        }

        if ((this.props.content_page === 'LoginPage' || this.props.content_page === 'RegistrationPage') && this.state.fillerAd !== 'assets/LargeRectangle-LoginRegistration.jpg') {
            this.setState({ fillerAd: 'assets/LargeRectangle-LoginRegistration.jpg' })
        } else if (!(this.props.content_page === 'LoginPage' || this.props.content_page === 'RegistrationPage') && this.state.fillerAd !== 'assets/LargeRectangle-RightColumn.jpg') {
            this.setState({ fillerAd: 'assets/LargeRectangle-RightColumn.jpg' })
        }

        if (this.props.content_page === "ListingPage") {
            if (this.props.search_data !== prevProps.search_data)
                this.getListingAds()
        } else if (this.props.content_page === "LookupPage") {
            if (this.props.search_data !== prevProps.search_data)
                this.getAdsBySearch()
        } else if (this.props.content_page !== prevProps.content_page) {
            this.getAds()
        }
    }

    getListingAds = () => {
        this.setState({
            ad1: {
                adLink: largeRectangle
            },
            ad2: {
                adLink: largeRectangle
            }
        })
        // fetch ads based on current listing being viewed on listing page
        if (this.props.search_data.loadAds)
            adService.getAdsByListing(this.props.search_data, "largeRectangle").then(response => {
                if (this.props.viewedAds.length < 135) {
                    this.showAds(response)
                    // this.updateAdDisplay(this.state.ad1.id, 'ad_1')
                    // this.updateAdDisplay(this.state.ad2.id, 'ad_2')
                }
            })
        else {
            if (this.timer1)
                this.stopFirstTimer();
            if (this.timer2)
                this.stopSecondTimer()
            this.currentAd1 = {};
            this.currentAd2 = {};
        }
    }

    getAdsBySearch = () => {
        // fetch ads based on search query entered by user
        this.setState({
            ad1: {
                adLink: largeRectangle
            },
            ad2: {
                adLink: largeRectangle
            }
        })
        adService.getAdsBySearch(this.props.search_data, 'largeRectangle').then(response => {
            this.showAds(response)
            if (this.props.viewedAds.length < 135) {
                this.showAds(response)
                // this.updateAdDisplay(this.state.ad1.id, 'ad_1')
                // this.updateAdDisplay(this.state.ad2.id, 'ad_2')
            }
        })
    }

    getAds = () => {
        this.setState({
            ad1: {
                adLink: largeRectangle
            },
            ad2: {
                adLink: largeRectangle
            }
        })
        adService.getAdvrtsByPageTypeAndLocation(this.props.current_coords, this.props.content_page, 'largeRectangle')
            .then(response => {
                if (this.props.viewedAds.length < 135) {
                    this.showAds(response)
                    // this.updateAdDisplay(this.state.ad1.id, 'ad_1')
                    // this.updateAdDisplay(this.state.ad2.id, 'ad_2')
                }
            })
    }

    showAds(ads) {
        this.showFirstAdvertisement(ads)
        if (Array.isArray(ads))
            this.showSecondAdvertisement(ads.reverse())
    }

    /**
     * First large rectangle advertisement 
     */
    showFirstAdvertisement = (ads) => {
        const storedAd1 = JSON.parse(sessionStorage.getItem('largeRectangle'))

        if (storedAd1 && storedAd1.page === this.props.content_page) {
            this.setState({ ad1: storedAd1, viewed1: { [storedAd1.id]: this.props.viewedAds.includes(storedAd1.id + ":largeRectangle") } })
            this.ad1Seconds = 30;
        }
        else if (!this.isEmpty(ads[0]) || ads[0]) {
            this.nextAd1 = ads.splice(0, 1)[0];

            if (this.props.viewedAds.includes(this.nextAd1.id + ":largeRectangle") || (this.state.ad1 && this.state.ad1.id === this.nextAd1.id) || (this.currentAd2 && this.currentAd2.id === this.nextAd1.id))
                this.showFirstAdvertisement(ads) // if next ad is already viewed/viewing (works for user own ad)
            else if (this.state.ad1.id && this.ad1Seconds < MAX_AD_TIME) {
                this.currentAd1 = { ...this.nextAd1 };
                this.currentAd1.adLink = process.env.REACT_APP_IMAGES_URL + this.currentAd1.advertisement;
                this.resetFirstAd();
                if (this.state.ad1.id === this.nextAd1.id)
                    this.showFirstAdvertisement(ads)
                else
                    this.nextAd1.adLink = process.env.REACT_APP_IMAGES_URL + this.nextAd1.advertisement;
            } else {
                this.currentAd1 = { ...this.nextAd1 };
                this.currentAd1.adLink = process.env.REACT_APP_IMAGES_URL + this.currentAd1.advertisement;
                this.ad1Seconds = 4
                this.resetFirstAd();
            }
            (this.state.isComponentVisible && this.props.isPageVisible && !this.props.isPopupOpen) && this.showFirstAdvertisement()
        } else if (!this.state.ad1.id || this.ad1Seconds >= MAX_AD_TIME) {
            this.setState({
                ad1: {
                    adLink: largeRectangle
                }
            })
            sessionStorage.removeItem('largeRectangle')
        } else if (this.state.ad1.id) {
            this.nextAd1 = null;
        }
    }

    startFirstTimer = () => {
        if (this.timer1)
            this.stopFirstTimer();

        const visibilityCondition = this.state.isAd1Visible && this.state.isPageVisible && !this.props.isPopupOpen;
        if (this.currentAd1.id && this.ad1Seconds <= MAX_AD_TIME && visibilityCondition) {
            if (this.nextAd1 === null) {
                this.currentAd1 = {}
                this.setState({ ad1: { adLink: largeRectangle } })
            }
            else this.timer1 = setInterval(() => {
                this.ad1Seconds += 1;
                if (this.ad1Seconds > MAX_AD_TIME) {
                    this.stopFirstTimer();
                    this.updateLrViews(this.state.ad1.id, 'ad_1');
                }
                // else {
                //     this.currentAd1 = { ...this.nextAd1 };
                //     this.resetFirstAd();
                // }
            }, 1000);
        }
    }

    resetFirstAd = () => {
        this.currentAd1.page = this.props.content_page
        sessionStorage.setItem("largeRectangle", JSON.stringify(this.currentAd1))
        this.ad1Seconds = 3;
        this.nextAd1 = {};
        this.setState({ ad1: this.currentAd1 }, () => {
            this.startFirstTimer()
        })
    }

    stopFirstTimer = () => {
        clearInterval(this.timer1);
    }

    /**
     * Second large rectangle advertisement 
     */
    showSecondAdvertisement = (ads) => {
        const storedAd2 = JSON.parse(sessionStorage.getItem('largeRectangle2'))
        if (storedAd2 && storedAd2.page === this.props.content_page) {
            this.setState({ ad2: storedAd2, viewed2: { [storedAd2.id]: this.props.viewedAds.includes(storedAd2.id + ":largeRectangle") } })
            this.ad2Seconds = 30;
        }
        else if (!this.isEmpty(ads[0]) || ads[0]) {
            this.nextAd2 = ads.splice(0, 1)[0];

            if (this.props.viewedAds.includes(this.nextAd2.id + ":largeRectangle") || (this.state.ad2 && this.state.ad2.id === this.nextAd2.id) || (this.currentAd1 && this.currentAd1.id === this.nextAd2.id))
                this.showSecondAdvertisement(ads) // if next ad is already viewed (works for user own ad)
            else if (this.state.ad2.id && this.ad2Seconds < MAX_AD_TIME) {
                this.currentAd2 = { ...this.nextAd2 };
                this.currentAd2.adLink = process.env.REACT_APP_IMAGES_URL + this.currentAd2.advertisement;
                this.resetSecondAd();
                if (this.state.ad2.id === this.nextAd2.id)
                    this.showSecondAdvertisement(ads)
                else
                    this.nextAd2.adLink = process.env.REACT_APP_IMAGES_URL + this.nextAd2.advertisement;
            } else {
                this.currentAd2 = { ...this.nextAd2 };
                this.currentAd2.adLink = process.env.REACT_APP_IMAGES_URL + this.currentAd2.advertisement;
                this.ad2Seconds = 2
                this.resetSecondAd();
            }
            (this.state.isComponentVisible && this.props.isPageVisible && !this.props.isPopupOpen) && this.startSecondTimer()
        } else if (!this.state.ad2.id || this.ad2Seconds >= MAX_AD_TIME) {
            this.setState({
                ad2: {
                    adLink: largeRectangle
                }
            })
            sessionStorage.removeItem('largeRectangle2')
        } else if (this.state.ad2.id) {
            this.nextAd2 = null;
        }
    }

    startSecondTimer = () => {
        if (this.timer2)
            this.stopSecondTimer();

        const visibilityCondition = this.state.isAd2Visible && this.state.isPageVisible && !this.props.isPopupOpen;
        //check <= with MAX_AD_TIME to prevent timer not continuing if other has completed when < condition was satisfied
        if (this.currentAd2.id && this.ad2Seconds <= MAX_AD_TIME && visibilityCondition) {
            if (this.nextAd2 === null) {
                this.currentAd2 = {}
                this.setState({ ad2: { adLink: largeRectangle } })
            }
            else this.timer2 = setInterval(() => {
                this.ad2Seconds += 1;
                if (this.ad2Seconds > MAX_AD_TIME) {
                    this.stopSecondTimer();
                    this.updateLrViews(this.state.ad2.id, 'ad_2');
                }
                //  else {
                //         this.currentAd2 = { ...this.nextAd2 };
                //         this.resetSecondAd();
                //     }

            }, 1000);
        }
    }

    resetSecondAd = () => {
        this.currentAd2.page = this.props.content_page
        sessionStorage.setItem("largeRectangle2", JSON.stringify(this.currentAd2))
        this.ad2Seconds = 2;
        this.nextAd2 = {};
        this.setState({ ad2: this.currentAd2 }, () => {
            this.startSecondTimer()
        })
    }

    stopSecondTimer = () => {
        clearInterval(this.timer2);
    }

    // updateAdDisplay = (id, spot) => {
    //     let listingData = {
    //         viewedOnListing: null,
    //         section: null,
    //     }
    //     if (this.props.search_data.listingId) {
    //         listingData = {
    //             viewedOnListing: parseInt(this.props.search_data.listingId),
    //             section: this.props.search_data.place,
    //         }
    //     }
    //     adService.updateAdDisplay(id, 'largeRectangle', listingData).then(result => {
    //         if (spot === 'ad_1')
    //             this.setState({ section1: { [id]: result } })
    //         else if (spot === 'ad_2')
    //             this.setState({ section2: { [id]: result } })
    //     })
    // }

    updateLrViews = (id, spot) => {
        //if the ad is being shown on listing page get current listing id and cal update views with listing id
        let listingData = {
            viewedOnListing: null,
            viewed: null,
            section: null,
        }
        if (this.props.search_data.listingId) {
            listingData = {
                viewedOnListing: parseInt(this.props.search_data.listingId),
                viewed: this.props.search_data.isViewed,
                section: this.props.search_data.place,
            }
        }
        adService.updateLrViews(id, 'largeRectangle', listingData).then(result => {
            if (spot === 'ad_1')
                this.setState({ viewed1: { [id]: result } })
            else if (spot === 'ad_2')
                this.setState({ viewed2: { [id]: result } })
            this.props.sendViewedAds(id + ":largeRectangle");
        })

    }

    updateVisibleAds = (isComponentVisible, isAd1) => {
        if (isAd1) {
            const adName = "largeRectangle1"
            if (isComponentVisible && !this.props.visibleAds.includes(adName)) {
                this.props.setVisibleAds([...this.props.visibleAds, adName])
            } else if (!isComponentVisible && this.props.visibleAds.includes(adName)) {
                this.props.setVisibleAds(this.props.visibleAds.filter(e => e !== adName))
            }
        } else {
            const adName = "largeRectangle2"
            if (isComponentVisible && !this.props.visibleAds.includes(adName)) {
                this.props.setVisibleAds([...this.props.visibleAds, adName])
            } else if (!isComponentVisible && this.props.visibleAds.includes(adName)) {
                this.props.setVisibleAds(this.props.visibleAds.filter(e => e !== adName))
            }
        }
    }

    render() {

        const column = this.props.content_page === 'RegistrationPage' ? 'col-md-3 left_lr' : 'col-md-3'
        return (
            <div className={`right_side_bar`} style={{ display: window.innerWidth >= 1340 ? "block" : "none" }}>

                <div className={`right_sider_inner ${this.state.freezClass}`} style={{ top: '170px' }}>
                    {/* <TitleHeading text={'Advertisements'} /> */}
                    <VizSensor
                        onChange={(isVisible) => {
                            if (this.state.isAd1Visible !== isVisible) {
                                this.setState({ isAd1Visible: isVisible && this.state.isPageVisible && !this.props.isPopupOpen });
                                this.updateVisibleAds(isVisible, true)
                            }
                        }}
                        partialVisibility
                        offset={{ bottom: 262 }}
                    >
                        <div className={styles.largeRectangleAd}>
                            <div className={`${styles.ad_position}`}>
                                <strong className="ad_mark">AD</strong>
                            </div>
                            {(!this.isEmpty(this.state.ad1) && this.state.ad1.mediaType === 'video')
                                ?
                                <video className={`img-fluid ${this.state.ad1.website ? 'cursor_pointer' : ''}`}
                                    onClick={() => adService.redirect(this.state.ad1.website)}
                                    autoPlay={true} loop src={this.state.ad1.adLink} type="video/mp4" onLoad={this.handleImageLoad} muted>
                                </video>
                                :
                                <img id='ad_1' className={`img-fluid ${this.state.ad1.website ? 'cursor_pointer' : ''}`} width='336' height='280'
                                    onClick={() => adService.redirect(this.state.ad1.website)}
                                    src={this.state.ad1.adLink} alt="Large Rectangle Ad" />
                            }
                            <div className={`${styles.ad_position}`}>
                                {(this.state.viewed1[this.state.ad1.id]) ? <i className="fas fa-check-circle viewed_mark"></i> : null}
                            </div>
                        </div>
                    </VizSensor>
                    <VizSensor
                        onChange={(isVisible) => {
                            if (this.state.isAd2Visible !== isVisible) {
                                this.setState({ isAd2Visible: isVisible && this.state.isPageVisible && !this.props.isPopupOpen });
                                this.updateVisibleAds(isVisible, false)
                            }
                        }}
                        partialVisibility
                        offset={{ bottom: 162 }}
                    >
                        <div className={styles.largeRectangleAd}>
                            <div className={`${styles.ad_position}`}>
                                <strong className="ad_mark">AD</strong>
                            </div>
                            {(!this.isEmpty(this.state.ad2) && this.state.ad2.mediaType === 'video')
                                ?
                                <video className={`img-fluid ${this.state.ad2.website ? 'cursor_pointer' : ''}`}
                                    onClick={() => adService.redirect(this.state.ad2.website)}
                                    autoPlay={true} loop src={this.state.ad2.adLink} type="video/mp4" onLoad={this.handleImageLoad} muted>
                                </video>
                                :
                                <img id='ad_2' className={`img-fluid ${this.state.ad2.website ? 'cursor_pointer' : ''}`} width='336' height='280'
                                    onClick={() => adService.redirect(this.state.ad2.website)}
                                    src={this.state.ad2.adLink} alt="Large Rectangle Ad" />
                            }
                            <div className={`${styles.ad_position}`}>
                                {(this.state.viewed2[this.state.ad2.id]) ? <i className="fas fa-check-circle viewed_mark"></i> : null}
                            </div>
                        </div>
                    </VizSensor>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        current_coords: state.current_coords,
        content_page: state.content_page,
        isPopupOpen: state.isPopupOpen,
        search_data: state.search_data,
        isPageVisible: state.isPageVisible,
        viewedAds: state.viewedAds,
        visibleAds: state.visibleAds,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendViewedAds: (viewedAds) => dispatch({ type: 'VIEWED_ADS', viewedAds: viewedAds }),
        setVisibleAds: (visibleAds) => dispatch({ type: 'VISIBLE_ADS', visibleAds: visibleAds }),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RightSideBar));
